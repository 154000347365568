import { FC } from 'react'
import screenshotNavigation from '../assets/images/screenshot_navigation.png'
import screenshotDiagram from '../assets/images/screenshot_diagram.png'
import screenshotDescription from '../assets/images/screenshot_description.png'

export const Home: FC = () => {
  const handleGetStarted = () => {
    window.open('https://marketplace.visualstudio.com/items?itemName=NerdiacsPteLtd.code-tree-viz', '_blank', 'noopener,noreferrer');
  };

  const handleRequestFeature = () => {
    window.open('https://forms.gle/YEsxGMuSnuQny34T7', '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="min-h-screen bg-slate-50">
      <header className="container section">
        <div className="flex flex-col items-center text-center gap-6">
          <h1 className="text-6xl font-bold">
            Supercharge Your Code Workflow
          </h1>
          <p className="text-xl text-accent max-w-2xl">
            View the code as a flow and use AI to understand it faster.
            <br />
            <i><b>(GO only and VSCode extension for now)</b></i>
          </p>
          <div className="flex gap-4">
            <button className="button" onClick={handleGetStarted}>Download</button>
            <button 
              className="button" 
              onClick={handleRequestFeature}
              style={{ backgroundColor: 'var(--color-surface)', color: 'var(--color-primary)' }}
            >
              Request Feature
            </button>
          </div>
        </div>
      </header>

      <section className="container my-16">
        <div style={{ position: 'relative', paddingBottom: '62.39%', height: 0 }}>
          <iframe 
            title="CodeTreeViz Demo Video"
            src="https://www.loom.com/embed/93882664a5ae43808b8c3146e4aa4dd9?sid=4e97960e-3d3a-41da-b23b-610fcf4db6f9" 
            allowFullScreen 
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 0 }}
          />
        </div>
      </section>

      <h2 className="text-4xl font-bold text-center mb-16">Features</h2>

      <section className="container space-y-24">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <div>
            <h3 className="text-3xl font-bold mb-4">Code Graph</h3>
            <p className="text-accent text-lg">Visualize code relationships and dependencies in an interactive graph. Understand the architecture and connections in your codebase at a glance.</p>
          </div>
          <div className="rounded-lg overflow-hidden shadow-lg">
            <img 
              src={screenshotNavigation} 
              alt="Navigate Code as a flow" 
              className="max-w-[500px] h-auto mx-auto" 
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <div className="order-2 md:order-1 rounded-lg overflow-hidden shadow-lg">
            <img 
              src={screenshotDiagram} 
              alt="Diagram Screenshot" 
              className="w-full max-w-[500px] h-auto mx-auto"
            />
          </div>
          <div className="order-1 md:order-2">
            <h3 className="text-3xl font-bold mb-4">Generate Flow Diagrams</h3>
            <p className="text-accent text-lg">Generate flow diagrams to understand the code flow and relationships.</p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <div>
            <h3 className="text-3xl font-bold mb-4">Function Description</h3>
            <p className="text-accent text-lg">Generate descriptions for functions to get a glance as to what they do.</p>
          </div>
          <div className="rounded-lg overflow-hidden shadow-lg">
            <img 
              src={screenshotDescription} 
              alt="Function Description" 
              className="w-full max-w-[500px] h-auto mx-auto"
            />
          </div>
        </div>
      </section>
    </div>
  )
} 