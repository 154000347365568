import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Home } from './pages/Home';
import { Updates } from './pages/Updates';
import { GoogleAnalytics } from './components/GoogleAnalytics';
import { Helmet } from 'react-helmet';
import { TwitterCard } from './components/TwitterCard';
import { SEO } from './components/SEO';
import screenshotNavigation from './assets/images/screenshot_navigation.png'

function App() {
  return (
    <>
      <Helmet>
        <SEO 
          title="CodeTreeViz: Understand Code Faster"
          description="Browse the code as a dynamic flow and use AI to document and understand it faster."
          image={screenshotNavigation}
          url="https://codetreeviz.nerdiacs.com"
          keywords={['documentation', 'code', 'programming', 'AI', 'code browsing', 'code navigation']}
          author="Faizan Naqvi"
          type="website"
        />
      </Helmet>
      <GoogleAnalytics measurementId="G-E5GBDLZ6P1" />
      <Router>
        <div className="App min-h-screen flex flex-col">
          <nav className="bg-white shadow-sm">
            <div className="container mx-auto px-4">
              <div className="flex justify-between h-16 items-center">
                <div className="flex-shrink-0">
                  <Link to="/" className="text-xl font-bold">CodeTreeViz: Understand Code Faster</Link>
                </div>
                <div className="flex space-x-8">
                  <Link 
                    to="/" 
                    className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Home
                  </Link>
                  <Link 
                    to="/updates" 
                    className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Updates
                  </Link>
                  <a 
                    href="https://forms.gle/owawhbzrAkUEXiwx8"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </nav>

          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/updates" element={<Updates />} />
            </Routes>
          </main>

          <footer className="bg-white py-4 shadow-sm mt-auto">
            <div className="container mx-auto px-4 text-center text-gray-600">
              <p>© {new Date().getFullYear()} Faizan Naqvi. All rights reserved.</p>
            </div>
          </footer>
        </div>
      </Router>
    </>
  );
}

export default App;
